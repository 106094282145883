export default {
    methods: {
        dollarToDollar: function(dollar) {
            return +dollar + 0.99
        },
        dollarToEuro: function(dollar) {
            return +(dollar * 0.79).toFixed(0) + 0.99
        },
        dollarToPLN: function(dollar) {
            return +(dollar * 3.75).toFixed(0) + 0.99;
        },
        getSymbol(currency) {
            const symbols = {
                usd : '$',
                eur : '€',
                pln : 'zł'
            }
            return symbols[currency];
        },
        getPrices(price) {
            return {
                usd: this.dollarToDollar(price),
                eur: this.dollarToEuro(price),
                pln: this.dollarToPLN(price),
                btc: '≈ ' + this.dollarToDollar(price) + '$',
            }
        }
    },
    
  }