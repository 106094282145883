<template>
<div class="crypto">
    <h4>Transfer cryptocurrency on my wallet and text me private message</h4>
    <p>Wallet Address: <span> bc1q4t05utrzll3e0sgzglp2xmj05yg3gxyg3huwm7 </span></p>
    
</div>

</template>

<script>

export default {
    name: 'Crypto',
    data() {
        return {
            wallet: 'xdxd',
        }
    }
};
</script>

<style scoped lang="scss">
.crypto {
    h4, p {
        font-family: 'Montserrat';
        font-size: 1.4rem;
    }

    h4 {
        font-weight: 300;
        color: $lightText;

    }

    p {
        margin-top: 3rem;
        color: $main;

        span {
            display: block;
            margin-top: .75rem;
            font-weight: 600;
            color: $white;
        }
    }
}
</style>
