<template>
    <section>
        <h2>Buy {{ beat.name }}</h2>
        <Steps @changeStep="changeStep" :parentStep="step" :data="data" />
        <div class="checkout">
            <div class="checkout__buttons">
                <button @click="step--" :class="{'checkout__prev-btn': true, 'active': step > 0 && step != 3}"><span>Prev</span></button>
                <button v-show="step !== 2" @click="step++"  :class="{'checkout__next-btn': true, 'active': isNextActive()}"><span>NEXT</span></button>
            </div>
            <transition-group name="fade" tag="div">
                <!-- CURRENCY -->
                <div v-show="step === 0"  key="currency-tab" class="box">
                    <div>
                        <h3>Pick the currency</h3>
                        <p>To buy a beat, you need to choose the currency in which you want to make the payment. Payments on this website are handled by third parties (paypal, stripe) which are completely safe.</p>
                        <Checkboxes  type="currency" @changeData="changeData" :items="currencies" />
                    </div>
                    <div>
                        <h4><IconInfo /> Pay differently</h4>
                        <p class="checkout__info">If you want to pay in a currency other than those given, contact me via the contact form or social media.</p>
                    </div>
                </div>

                <!-- LICENSIONS -->
                <div v-show="step === 1" key="licensions-tab" class="box">
                    <div>
                        <h3>Pick the licensions</h3>
                        <p>If you have chosen one of the licenses, choose it from the ones below. The license gives you certain rights once your song is released to the public. To confirm the rights to use the beat you will recieve a special license / document.
                        </p>
                        <Checkboxes type="licension" @changeData="changeData" :items="licensions" />
                    </div>
                    <div>
                        <h4><IconInfo />{{ beat.name }} {{ data.licension }} licension contains</h4>
                        <!-- this.licensions[this.getLicensionIndexByName(licension)]+ -->
                        <ul class="checkout__licension">
                            <li v-show="text" v-for="(text, key) in licensions[0] && licensions[getLicensionIndex()].description.split('</br>')" :key="key">
                            <span></span>
                            {{ beat.stems ? text : text.replace('+ Stems / Trackout','') }}
                            </li>
                        </ul>
                    </div>
                </div>

                <!-- PAYMENT -->
                <div v-show="step === 2" key="payment-tab" class="box">
                    <div>
                        <h3>Select payment</h3>
                        <p>Choose the service you want to pay with. Payment solution providers are completely secure and you can pay with your ATM card. If you want to change the currency or license, please go back to the previous steps.
                        </p>
                        <Checkboxes type="payment" @changeData="changeData" :items="data.currency === 'btc' ? [payments[2]] : [payments[0], payments[1]] " />
                    </div>
                    <div>
                        <h4>Summary</h4>
                        <ul class="checkout__details">
                            <li><span>Beat :</span> {{ beat.name }}</li>
                            <li><span>Currency :</span> {{ data.currency }}</li>
                            <li><span>Licension :</span> {{ data.licension }}</li>
                            <li class="checkout__details--sum"><span>Sum :</span> {{ data.price }} {{ getSymbol(data.currency) }}</li>
                            <li><span>Payment method:</span> {{ data.currency == 'btc' || data.payment == 'crypto' ? 'Crypto' : data.payment }}</li>
                        </ul>

                        <div class="checkout__paypal">
                            <Paypal v-show="data.payment == 'paypal' && data.currency !== 'btc'" :currency="data.currency" :price="data.price" :token="getToken()"/> 
                            <Stripe v-show="data.payment == 'stripe' && data.currency !== 'btc'" :currency="data.currency" :licension="data.licension" :token="getToken()" />
                            <Crypto v-show="data.payment == 'crypto' && data.currency == 'btc'" :currency="data.currency" :licension="data.licension" :token="getToken()" />
                        </div>
                    </div>
                </div>

                <!-- DOWNLOAD -->
                  <div v-show="step === 3" key="download-tab" class="box download">
                    <div>
                        <h3>Download beat</h3>
                        <p>Thank you, the transaction was successful. Now you can download the beat in .mp3 format. If you have encountered any problems, write to me via contact form or via social media. You can also apply there for the license / document and stems / trackout (if your license contains them).
                        </p>

                           <router-link to="/contact">
                           Contact Form
                            </router-link>
                    </div>

                    <div>
                          <a
                            class="download-btn"
                            :href="beat.audio"
                            v-text="'Download mp3'"
                            @click.prevent="downloadBeat({url: beat.audio, label: beat.name})" />
                    </div>
                
                </div>

            </transition-group>
           
        </div>
          
       

    </section>
</template>

<script>
import { LicensionsService } from "@/api";
import axios from 'axios';

import Checkboxes from '../components/checkout/Checkboxes'
import Steps from '../components/checkout/Steps'

import Stripe from '../components/checkout/Stripe'
import Paypal from '../components/checkout/Paypal'
import Crypto from '../components/checkout/Crypto'

import currencyExchange from '@/mixins/currency'




// import IconBase from '@/assets/icons/IconBase'
// import IconArrow from '@/assets/icons/IconArrow.vue'
import IconInfo from '@/assets/icons/IconInfo.vue'



// import { StripeCheckout } from '@vue-stripe/vue-stripe';


export default {
    name: 'Licensions',
    mixins: [currencyExchange],
    components: {
        Paypal,
        Stripe,
        Crypto,
        // StripeCheckout,
        Checkboxes,
        // IconArrow,
        IconInfo,
        // IconBase,
        Steps
    },
    data() {
        return {
            step: 0,
            data: {
                currency: null,
                payment: '',
                price: 100,
                licension: null
            },
            prices: {},
            licensions: [],
            currencies: ['usd', 'eur', 'pln', 'btc'],
            payments: ['paypal', 'stripe', 'crypto'],
        }
    },
    props: {
        beat: Object
    },
    mounted() {
        this.getLicensions();
        if(this.$route.query.on === this.getToken()) {
            this.step = 3;
        }
    },
    methods: {
        getToken() {
            return `su${this.beat.name[0].toLowerCase() + this.beat.id}`;
        },
        changeStep(step) {
            this.step = step;
        },
        changeData({type, val}) {
            this.data[type] = val
          
            const prices = this.getPrices(this.licensions[this.getLicensionIndex()].price);
            this.data.price = prices[this.data.currency];
        },
        isNextActive() {
                return (this.step === 0 && this.data.currency) || this.step === 1 && this.data.licension;
        },
        getLicensions() {
            LicensionsService.getAll()
                .then(res => {        
                    this.licensions = res;
                    this.prices = {
                        basic: this.getPrices(res[0].price),
                        premium: this.getPrices(res[1].price),
                        exclusive: this.getPrices(res[2].price)
                    }

                    const {licension, currency} = this.data;

                    this.data.price = licension && currency ? this.prices[this.data.licension][this.data.currency] : 100;

                })
        },
        getLicensionIndex() {
            const lic = this.data.licension && this.data.licension.toLowerCase();
            return lic == 'basic' ? 0 : lic == 'premium' ? 1 : 2;
        },
        downloadBeat ({ url, label }) {
        axios.get(url, { responseType: 'blob' })
            .then(response => {
                const blob = new Blob([response.data], { type: 'audio/mp3' })
                const link = document.createElement('a')
                link.href = URL.createObjectURL(blob)
                link.download = label
                link.click()
                URL.revokeObjectURL(link.href)
            }).catch(console.error)
        }
    },

}
</script>

<style lang="scss" scoped>
.fade-enter, .fade-enter-active, .fade-enter.to {
    opacity: 0;  
    position: absolute;
}

.fade-leave-to {
    opacity: 0;
}

.fade-move {
    border: 1px solid red;
}

section {
    margin: 8rem 0px;
}

h2 {
    @include fontPoppins;
    color: $white;
    font-size: 2.4rem;
    // text-align: center;
}

h3, h4 {
    @include fontPoppins;
    font-size: 2rem;
    color: $white;
}
h4 {
    font-size: 1.5rem;
    color: $main;
    display: flex;
    align-items: center;
    opacity: 0.8;


    svg {
        margin-right: 1rem;
        opacity: 0.6;
    }

    & + p {
        opacity: 0.6;
    }
  
}

p {
    @include fontMontserrat;
    color: $middleText;
    margin: 1rem 0px 3rem;
    max-width: 32rem;
    line-height: 1.5;
}

.box {
    display: grid;
    row-gap: 4rem;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    transition: opacity .16s;

    @include mq($from: desktop) {
        gap: 2rem;
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }

    & > div:first-of-type {
        grid-column-start: 1;
        grid-column-end: 3;
    }
}

.steps {
    display: flex;
    gap: 2rem;
    margin: 2rem 0px;

    button {
        @include fontPoppins;
        color: $white;
        cursor: pointer;

        &.active {
            color: $main;
        }
    }
}

.checkout {
    position: relative;
    padding: 2rem 4rem;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.6) 0%, rgba(255, 255, 255, 0) 96%);
    border-radius: 10px;

    @include mq($from: tablet) {
        padding: 7rem 9rem;
    }

    @include mq($from: desktop) {
        height: 40rem;
    }

    &__details {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        padding: 0px;
        margin-top: 2rem;

        li {
            list-style: none;
            font-size: 1.6rem;
            color: $lightText;
            text-transform: uppercase;
            font-family: 'Montserrat';

       
            span {
                display: inline-block;
                width: 8rem;
                text-transform: none;
                font-size: 1.2rem;
                opacity: 0.7;
            }
        }

        li.checkout__details--sum {
            color: $main;

            span {
                color: $white;
            }
        }

    }

    &__buttons {
        display: flex;
        justify-content: space-between;
        margin-bottom: 2rem;
        @include mq($from: desktop) {
            margin-bottom: 0rem;
        }
    }

    &__next-btn, &__prev-btn {
        top: 0;
        right: 0;
        height: 100%;
        display: block;
        padding: .75rem 0px;
        border-radius: 6px;
        cursor: pointer;
        color: $white;
        border-radius: 8px;
        transition: opacity .22s, background-color .22s;
        opacity: 0.1;
        pointer-events: none;

        &:hover {
            background-color: rgba(0, 0, 0, 0.1);
        }

        &.active {
            opacity: 0.8;
            pointer-events: auto;
        }


        span {
            font-family: 'Poppins';
            letter-spacing: 1px;
            font-size: 2rem;
            display: block;
            text-transform: uppercase;
        }

        @include mq($from: tablet) {
            position: absolute;
            padding: .75rem 2rem;

            span {
                transform: rotate(-90deg);
            }
        }

    }

    &__prev-btn {
        right: unset;
        left: 0;

        span {
            @include mq($from: tablet) {
                transform: rotate(90deg);
            }
        }
    }

    &__paypal {
        margin-top: 2rem;
    }

    &__licension {
        display: flex;
        flex-direction: column;
        gap: .75rem;
        padding: 0px;
        margin-top: 2rem;

        li {
            font-family: 'Poppins';
            list-style: none;
            font-size: 1.2rem;
            color: $middleText;

            span {
                display: inline-block;
                width: .5rem;
                height: .5rem;
                background-color: $darkText;
                border-radius: 50%;
                transform: translateY(-2px);
                margin-right: 4px;
            }
        }
    }

    .box.download a {
        display: inline-block;
        background: rgba(0, 0, 0, 0.433);
        @include fontPoppins;
        padding: 1.5rem;
        border-radius: 6px;
        color: $middleText;
        transition: background-color .22s, color .2s;
        margin-top: 2rem;

        &:hover {
            background: rgba(0, 0, 0, 0.6);
            color: $main;
        }

        &.download-btn {
            padding: 2rem;
            font-size: 1.75rem;
            text-transform: uppercase;
            color: $main;

            &:hover {
                color: $white;
            }
        }
    }
}
</style>

