<template>
  <div>
    <stripe-checkout
      ref="checkoutRef"
      mode="payment"
      :pk="publishableKey"
      :line-items="currency && licension ? lineItems[currency][licension.toLowerCase()] : []"
      :success-url="successURL"
      :cancel-url="cancelURL"
      @loading="v => loading = v"
    />
    <button @click="submit">Pay <span>with Stripe</span></button>
  </div>
</template>

<script>
import { StripeCheckout } from '@vue-stripe/vue-stripe';
export default {
    name: 'Stripe',
    props: ['currency', 'licension', 'token'],
    
    components: {
        StripeCheckout,
    },
    data () {
        this.publishableKey = 'pk_test_51KJky6AklPM9Ks94iyTakyYI8mOVRTus4yLoPkvmcZFfDE7OaTCECmSLaanrGasZiAeCbXkkESLXzS76roMDU8fI00Mq96E2JS';

        return {
            loading: false,
            lineItems: 
                {
                    usd: {
                        basic: [{
                            price: 'price_1KJlOyAklPM9Ks94Q7B0R2TK',
                            quantity: 1,
                        }],
                        premium: [{
                            price: 'price_1KJlPQAklPM9Ks94djWFHo9u',
                            quantity: 1,
                        }],
                        exclusive: [{
                            price: 'price_1KJlPkAklPM9Ks94J4dwtgR8',
                            quantity: 1,
                        }]
                    },
                    eur: {
                        basic: [{
                            price: 'price_1KJlQUAklPM9Ks94pnzSv0Re',
                            quantity: 1,
                        }],
                        premium: [{
                            price: 'price_1KJlQzAklPM9Ks94yGK9qTjc',
                            quantity: 1,
                        }],
                        exclusive: [{
                            price: 'price_1KJlRMAklPM9Ks944Sxz1HYZ',
                            quantity: 1,
                        }]
                    },
                    pln: {
                        basic: [{
                            price: 'price_1KJlRfAklPM9Ks94D0jDHlKA',
                            quantity: 1,
                        }],
                        premium: [{
                            price: 'price_1KJlS5AklPM9Ks94Q0cjrlSi',
                            quantity: 1,
                        }],
                        exclusive: [{
                            price: 'price_1KJlSVAklPM9Ks94oIe3KBsz',
                            quantity: 1,
                        }]
                    },
                    btc: {basic: [], premium: [], exclusive: []}
                }
            ,
            successURL: `${window.location.origin + this.$route.path}?on=${this.token}`,
            cancelURL: window.location.origin + this.$route.path,
        };
    },
    methods: {
    submit () {
        // You will be redirected to Stripe's secure checkout page
        this.$refs.checkoutRef.redirectToCheckout();
    },
    },
};
</script>

<style lang="scss" scoped>
    button {
        color: $black;
        text-transform: uppercase;
        letter-spacing: 1.2px;
        background: #F2BA36;
        padding: .75rem 2rem;
        border-radius: 4px;
        font-weight: 600;
        font-family: 'Poppins';
        cursor: pointer;
        transition: filter .22s;
        
        &:hover {
            filter: brightness(110%);
        }

        span {
            font-weight: 300;
            text-transform: lowercase;
            font-size: 1rem;
        }
    }
</style>