<template>
  <div class="page">
        <Breadcrumbs/>
        <BeatPlayer :targetBeat="targetBeat" />
        <BeatCheckout :beat="targetBeat" />
        <LicensionsAlt :beat="targetBeat" />




    <!-- <div class="beat__related">
        <h3>Related tracks:</h3>
        <ul v-if="related">
            <li v-for="beat in related" :key="beat.id">
                <router-link :to='"/beat/" + beat.slug '>{{ beat.name }}</router-link>
            </li>
        </ul>
        <h2 v-else> No related tracks</h2>
    </div> -->

  </div>
</template>

<script>
import BeatCheckout from '@/components/BeatCheckout'
import BeatPlayer from '@/components/BeatPlayer'
import LicensionsAlt from '@/components/LicensionsAlt'



import { mapGetters, mapState } from 'vuex'

// import Beat from '@/components/Beat'

export default {
    name: "BeatPage",
    components: {
        BeatPlayer,
        BeatCheckout,
        LicensionsAlt
    },
    props: {
        slug: String
    },
    computed: {
        ...mapState({allBeats: (state) => state.beats.all}),
        ...mapGetters('player',['getCurrBeat']),
        ...mapGetters('player',['getIsPlaying']),
        ...mapGetters('favourites',['getFavourites']),
        targetBeat() {
               return this.allBeats?.find((v) => v.slug === this.slug)
        },
        related() {
            let relatedTracks =  []
            
            this.allBeats.forEach(beat => {
                beat.tags.forEach(tag => {
                    if(this.targetBeat.tags[0].name === tag.name && beat.name != this.targetBeat.name) relatedTracks.push(beat)
                })
            })
            
            relatedTracks = [...new Set(relatedTracks)]
            if(relatedTracks.length > 3) relatedTracks.length = 3

            return relatedTracks;
        }
    },

    mounted() {
        window.scrollTo({
            top: 0,
            behaviour: 'smooth'
        })
    },
}
</script>

<style lang="scss" scoped>
.page {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 86%;
    max-width: 1300px;
    margin: 0 auto;
    margin-top: 16rem;


        // &__related {
        //     border-top: 1px solid rgba(255, 255, 255, 0.1);
        //     width: 100%;
        //     @include fontPoppins;
        //     color: $white;
        //     position: relative;
        //     margin-top: 40px;

        //     @include mq($to: desktop) {
        //         display: none;
        //     }

        //     & h3 {
        //         margin-top: 20px;
        //         font-weight: 300;
        //     }

        //     & ul {
        //         display: flex;
        //         flex-direction: column;
        //         justify-content: space-around;
        //         height: 86%;
        //         margin-top: 20px;

        //         & li {
        //             display: flex;
        //             flex-direction: row;
        //             align-items: center;
        //             width: auto;

        //             & p {
        //                 margin-left: 10px;
        //                 font-size: 14px;

        //                 @include mq($from: mobile) {
        //                     font-size: 16px;
        //                 }
                        
        //             }

        //             & img {
        //                 width: 42px;
        //                 height: 42px;
        //                 object-fit: cover;
        //                 border-radius: 10px;
        //             }
        //         }
        //     }
        // }

        // &__licensions {
        //     width: 100%;
           
        //     @include mq($from: desktop) {
        //         width: 80%;
        //     }
        // }
    }
</style>