<template>
  <PayPal 
        :amount="price + ''"
        :currency="currency ? currency.toUpperCase() : ''"
        :client="credentials"
        @payment-completed="completed"
        env="production"/> 

        <!-- ENV: PRODUCTION  -->
    <!-- @payment-cancelled="cancelled" -->
    <!-- @payment-authorized="authorized" -->

</template>

<script>
import PayPal from 'vue-paypal-checkout'

export default {
    name: 'Paypal',
    data() {
        return {
            credentials: {
                sandbox: 'AY732M0lYQrtg-_AhUL9FWA2j4L-qaDz8UrpfbJ9sD7VTK2VhNzJCf0AkzNtINtveetipqrqzD1O2b1n',
                production: 'Af_Bgq8bQ5WUhj9_ozPYvVi5gjHUQJeeskiVrfb8QdYLZO0ENcfqr2_rTrTqYwooz0XHwoe3AqOgfgpG',
            },
        }
    },
    props: ['currency', 'price', 'token'],
    components: {
        PayPal,
    },
    methods: {
        completed() {
            window.location.href = `${window.location.origin + this.$route.path}?on=${this.token}`
        },
    }
};
</script>
