<template>
        <nav class="steps">
            <button 
                @click="stepClick(0)" 
                :class="{active: step >= 0, disabled: step == 3}">
                Currency
            </button>
            <icon-base width="24" height="24" :stroke="color(0, 'currency')" color="transparent" viewBox="0 0 24 24"><icon-arrow /></icon-base>

            <button @click="stepClick(1)" :class="{active: step >= 1, disabled: !data.currency }">Licension</button>
            <icon-base width="24" height="24" :stroke="color(1, 'licension')" color="transparent"
             viewBox="0 0 24 24"><icon-arrow /></icon-base>

            <button @click="stepClick(2)" :class="{active: step >= 2, disabled: !data.licension }">Payment</button>
            <icon-base width="24" height="24" :stroke="'#464646'" color="transparent" viewBox="0 0 24 24"><icon-arrow /></icon-base>
            
            <button @click="stepClick(3)"  :class="{active: step >= 3, disabled: step != 3}">Download</button>
        </nav>
</template>

<script>

import IconBase from '@/assets/icons/IconBase'
import IconArrow from '@/assets/icons/IconArrow.vue'




export default {
    name: 'Steps',
    components: {
        IconArrow,
        IconBase
    },
    props: {
        parentStep: Number,
        data: Object
    },
    data() {
        return {
            step: 0,
        }
    },
    mounted() {

    },
    methods: {
        stepClick(step) {
            this.step = step;
            this.$emit('changeStep', this.step)
        },
        color(val, type) {
            return !this.data[type] ? '#464646' : this.step > val ? '#ad8213' : 'white' ;

        },
        isDisabled() {

        }
    },
    watch: {
        parentStep(next) {
            this.step = next;
        }
    }
}
</script>

<style lang="scss" scoped>
.steps {
    display: flex;
    gap: 2rem;
    margin: 2rem 0px;

    button {
        @include fontPoppins;
        font-size: 1.2rem;
        color: $white;
        cursor: pointer;
        transition: color .16s, opacity .16s;

        @include mq($from: tablet) {
            font-size: 1.5rem;
        }

        &.active {
            color: $main;
        }

        &.disabled {
            opacity: 0.2;
            pointer-events: none;
        }
    }

    svg {
        display: none;
        
        &, g, path {
            transition: stroke .22s;
        }
        
        @include mq($from: mobile) {
            display: block;
        }
    }

}

</style>

