<template>
    <section class="beat">
        <div class="beat__img">
            <img :src="targetBeat.image" alt="beat image">
        </div>

        <div class="beat__main">
                <div>
                    <div class="beat__main__header">
                        <div class="buttons" @click="togglePlay">
                        <button  class="buttons__play">
                            <icon-base width="33" height="38" iconColor="grey" viewBox="0 0 33 38" >
                                <icon-play stroke="lightGrey" :isPlaying="isPlaying"/>
                            </icon-base>
                        </button>
                        <button class="buttons__stop" :class="{is_active: isPlaying}" />
                        </div>
                        <div class="beat__main__header__title">
                            <h1>{{targetBeat.name}}</h1>
                            <ul>
                                <li v-for="tag in targetBeat.tags" :key="tag.name">#{{tag}}</li>
                            </ul>
                        </div>
                    </div>

                    <div class="beat__main__details">
                        <ul>
                            <li><span>Duration:</span> {{targetBeat.time}}s</li>
                            <li><span>Tempo:</span> {{targetBeat.tempo}} BPM</li>
                            <li><span>Tune:</span> {{targetBeat.tune}}</li>
                        </ul>
                    </div>
                </div>

                <av-waveform
                    audio-class="audio"
                    :audio-src="targetBeat.audio"
                    :audio-controls="false"
                    played-line-color="goldenrod"
                    noplayed-line-color="white"
                    :playtime="false"
                    :playtime-slider-width="0"
                    playtime-slider-color="white"
                    :canv-width="canvasWidth()"
                    :canv-height="canvasHeight()"
                    canv-class="spectrum"
                ></av-waveform>
        </div>
    </section>
        



    <!-- <div class="beat__related">
        <h3>Related tracks:</h3>
        <ul v-if="related">
            <li v-for="beat in related" :key="beat.id">
                <router-link :to='"/beat/" + beat.slug '>{{ beat.name }}</router-link>
            </li>
        </ul>
        <h2 v-else> No related tracks</h2>
    </div> -->

      <!-- <div class="beat__licensions">
          <CartLicensions :beatName="targetBeat.name" :stems="targetBeat.stems" :beat="targetBeat"/>
      </div> -->
</template>

<script>
import IconBase from '@/assets/icons/IconBase.vue';
import IconPlay from '@/assets/icons/IconPlay'
import { mapGetters, mapState } from 'vuex'

export default {
    name: "BeatPlayer",
    components: {
        IconBase,
        IconPlay,
    },
    props: ['targetBeat'],
    data() {
        return {
            isPlaying: false,
            isFavourite: false,
            audio: '',
            mainPlayer: ''
        }
    },

    computed: {
        ...mapState({allBeats: (state) => state.beats.all}),
        ...mapGetters('favourites',['getFavourites']),
        related() {
            let relatedTracks =  []
            
            this.allBeats.forEach(beat => {
                beat.tags.forEach(tag => {
                    if(this.targetBeat.tags[0].name === tag.name && beat.name != this.targetBeat.name) relatedTracks.push(beat)
                })
            })
            
            relatedTracks = [...new Set(relatedTracks)]
            if(relatedTracks.length > 3) relatedTracks.length = 3

            return relatedTracks;
        }
    },
    beforeRouteLeave (to, from, next) {
        this.mainPlayer.classList.remove('is_blocked');
        next();
    },
    mounted() {
        this.audio = document.querySelector('.audio');
        this.mainPlayer = document.querySelector('.player ')

        this.mainPlayer.classList.remove('is_open');
        this.mainPlayer.classList.add('is_blocked');

        this.$store.dispatch("player/setIsPlaying", {
            isPlaying: false,
            clickedID: this.targetBeat.id
        });
    },
    methods: {
        canvasWidth() {
            const width = window.innerWidth;
            let canvasWidth;

            if(width > 1200) {
                const width = window.innerWidth * 0.86 - 260;
                canvasWidth = width > 1050 ? 1050 : width;
            } else if (width > 980) {
                canvasWidth = window.innerWidth * 0.86 - 220;
            } else if (width > 768) {
                canvasWidth = window.innerWidth * 0.86 - 180;
            } else if(width > 567) {
                canvasWidth = window.innerWidth * 0.86 - 170;
            } else {
                canvasWidth = window.innerWidth * 0.86 + 10;
            }

            return canvasWidth > 1200 ? 1200 : canvasWidth;
        },
        canvasHeight() {
            return window.innerWidth > 980 ? 100 : 60;
        },
        togglePlay(){
            this.isPlaying = !this.isPlaying     
  
            
            if(this.isPlaying) {
                this.audio.play();
            } else {
                this.audio.pause();
            }
        },
    },
    watch: {
        getFavourites() {
            if(this.getFavourites.includes(this.targetBeat.id)) return this.isFavourite = true
            this.isFavourite = false
        },
    }
}
</script>

<style lang="scss" scoped>
    .beat {
        display: flex;
        flex-direction: column;
        margin: 2rem 0;
        gap: 2rem;

        @include mq($from: mobile) {
            flex-direction: row;
            margin-top: 3rem;
        }

        @include mq($from: tablet) {
            gap: 4rem;
            margin-top: 4rem;
        }

        &__img {
            min-width: 120px;
            height: 200px;
            position: relative;

            @include mq($from: mobile) {
                min-width: 160px;
                height: 160px;
            }

            @include mq($from: tablet) {
                min-width: 160px;
                height: 160px;
            }

            @include mq($from: desktop) {
                min-width: 200px;
                height: 200px;
            }

            @include mq($from: wide) {
                min-width: 240px;
                height: 240px;
            }
            
            & img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 10px;
            }
        }


        &__main {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            & .buttons {
                @include playStopBtn;
                position: relative;
                width: 34px;
                height: 40px;
                cursor: pointer;
                transform: scale(0.7);

                @include mq($from: tablet) {
                    transform: scale(1);
                }


                &__play {
                    margin: 0;
                }

                &__stop {
                    top: 0;
                    left: 0;
                    width: 34px;
                    transform: none;

                    &::before {
                        transform: translate(-7px, -50%);
                    }

                    &::after {
                        transform: translate(7px, -50%);
                    }
                }
            }

            &__header {
                display: flex;
                align-items: center;
                gap: 1rem;

                @include mq($from: tablet) {
                    gap: 2rem;
                }

                &__title {
                    display: flex;
                    align-items: baseline;
                    gap: 1rem;

                    @include mq($from: mobile) {
                        gap: 2rem;
                    }

                    & h1 {
                        @include fontPoppins;
                        color: $white;
                        line-height: 1;
                        text-align: left;
                        font-weight: 300;
                        font-size: 2.8rem;

                        @include mq($from: tablet) {
                            font-size: 3.6rem;
                        }
                        
                    }

                    ul {
                        display: flex;
                        gap: 1rem;

                        @include mq($from: mobile) {
                            gap: 2rem;
                        }

                        li {
                            @include fontPoppins;
                            font-size: 12px;
                            list-style: none;

                            
                            @include mq($from: mobile) {
                                gap: 2rem;
                                font-size: 16px;
                            }
                        }
                    }
                }
            }
            
            &__details ul {
                display: flex;
                margin-top: 10px;
                margin-bottom: 2rem;
                gap: 2rem;

                @include mq($from: tablet) {
                    margin-top: 18px;
                }


                & li {
                    @include fontPoppins;
                    color: $white;
                    list-style: none;
                    position: relative;
                    font-weight: 100;
                    font-size: 13px;
                    margin-bottom: 6px;

                    @include mq($from: mobile) {
                        font-size: 15px;
                    }

                    span {
                        opacity: 0.6;
                        font-size: 10px;
                    }
                }
            }
        }
    }
</style>