<template>
  <div :class="'checkboxes ' + type">
    <template v-for="(item, index) in items">
      <input 
        :class="{active: isCurrent(item)}"
        name="currencies" 
        type="radio" 
        @change="change"
        v-model="currentCheckbox" 
        :key="'input-' + index"
        :value="type === 'licension' ? item.title : item" 
        :id="type === 'licension' ? item.title : item">

      <label  
      :key="'label-' + index" 
      :for="type == 'licension' ? item.title : item">
        <div v-if="type == 'currency'" class="icon-wrapper" :style="{color: color(item)}">
          <IconDollar v-if="item === 'usd'" />
          <IconEuro v-else-if="item === 'eur'" />
          <IconPln v-else-if="item === 'pln'" />
          <IconBitcoin v-else-if="item === 'btc'" />
        </div>
        <p v-else-if="type === 'licension'" class="licension">{{ item.title }}</p>
        <div v-if="type == 'payment'" class="payment" :style="{color: color(item)}">
          <IconPaypal v-if="item === 'paypal'" />
          <IconStripe v-else-if="item === 'stripe'" />
          <IconCrypto v-else-if="item === 'crypto'" />
        </div>

      </label>
    </template>
  </div>
</template>

<script>
import IconDollar from '@/assets/icons/IconDollar.vue'
import IconPln from '@/assets/icons/IconPln.vue'
import IconEuro from '@/assets/icons/IconEuro.vue'
import IconBitcoin from '@/assets/icons/IconBitcoin.vue'
import IconPaypal from '@/assets/icons/IconPaypal.vue'
import IconCrypto from '@/assets/icons/IconCrypto.vue'
import IconStripe from '@/assets/icons/IconStripe.vue'






export default {
    name: 'Checkboxes',
    props: {
      items: Array,
      type: String,
    },
    data() {
      return {
        currentCheckbox: ''
      }
    },
    components: {
      IconDollar,
      IconEuro,
      IconPln,
      IconBitcoin,
      IconPaypal,
      IconCrypto,
      IconStripe
    },

    methods: {
      change() {
        this.$emit('changeData', {type: this.type, val: this.currentCheckbox})
      },
      color(item) {
        return item === this.currentCheckbox ? '#89630F' : '#ffffff';
      },
      isCurrent(item) {
        return (this.type === 'licension' ? item.title : item) === this.currentCheckbox;
      }

    
    },
}
</script>

<style lang="scss" scoped>
.checkboxes {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;


  input {
    display: none;

    & + label {
      display: flex;
      justify-content: center;
      align-items: center;
      // width: 3.6rem;
      // height: 2rem;
      border: 1px solid $middleText;
      border-radius: 50%;
      cursor: pointer;
      // transition: all .22s;

      &:hover {
        border-color: $lightText;
      }

      // @include mq($from: mobile) {
        width: 6.4rem;
        height: 6.4rem;
      // }

      p {
        font-family: 'Montserrat';
        color: $white;
      }
    }
  }

  &.currency {
    input.active + label,
     input:checked + label {
        border: 1px solid $main !important;
     }
  }

  &.licension {
    gap: 6rem;
    row-gap: 0rem;
    padding-left: 3rem;
    input.active, input:checked {
      & + label {
        border: none;

        p {
         color: $main;
        }

        
        &::before {
          @include tickSVG;
        }
      }
    }

    label {
      position: relative;
      border: none;
      border-radius: 0px;
      font-size: 1.8rem;
      width: auto;
      @include tick;

      &::before {
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }

  &.payment {
    gap: 6rem;
    row-gap: 2rem;
    padding-left:3rem;

    input.active, input:checked {
      & + label {
        border: 1px solid rgba(255, 255, 255, 0.213);

        p {
         color: $main;
        }

        &::before {
          @include tickSVG;
        }
      }
    }
    label {
        position: relative;
        border: none;
        background: rgba(0, 0, 0, 0.275);
        width: auto;
        height: auto;
        border-radius: 4px;
        padding: 1.4rem 2rem;
        transition: opacity .2s;
        border: 1px solid transparent;

        &:hover {
          border-color: transparent;
        } 

        @include tick;
    }



     & .payment {
        display: flex;
        gap: 1rem;
     }
  }
}
</style>

