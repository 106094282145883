<template>
    <section class="licensions" id="licensions">
        <h2>Licensing Info</h2>
        <div class="licensions__container">


                <div class="licensions__list">
                        <ul v-for="({title, description, price}) in lics" :key="title">
                            <h3>{{ title }}</h3>
                            <h4>{{ dollarToDollar(price) }}$</h4>
                            <li v-for="(text, key) in description.split('</br>')" :key="key">
                            {{  beat.stems ? text : text.replace('+ Stems / Trackout','') }}
                            </li>
                            <!-- <a>Read license</a> -->
                            <div class="licensions__list__offer">But 3, Get 2 free</div>
                        </ul>
                </div>
        </div>
    </section>
</template>

<script>
import { LicensionsService } from "@/api";
import currencyExchange from '@/mixins/currency'


export default {
    name: 'Licensions',
    props: ['beat'],
    mixins: [currencyExchange],
    data() {
        return {
            lics: Array,
        }
    },
    mounted() {
        this.getLicensions();
    },
    methods: {
        async getLicensions() {
            await LicensionsService.getAll()
                .then(res => {        
                    this.lics = res;
                })
        },
    }
}
</script>

<style lang="scss" scoped>
    h2 {
        @include fontPoppins;
        text-align: center;
        font-size: 2rem;
        color: $main;
        opacity: 0.72;
    }

    .licensions {
        margin-bottom: 10rem;
        &__list {
            margin-top: 4rem;
            display: grid;
            gap: 10rem;

            @include mq($from: tablet) {
                grid-template-columns: repeat(3, minmax(0, 1fr));
                gap: 3rem;
            }

            @include mq($from: desktop) {
                padding: 0 10%;
            }

            h3 {
                @include fontPoppins;
                color: $lightText;
                text-align: center;
                font-size: 2rem;
                margin: 0px;
            }

            h4 {
                @include fontPoppins;
                font-size: 3rem;
                color: $white;
                text-align: center;
                margin: 1rem 0px;
            }
        

            li {
                list-style: none;
                text-align: center;
                @include fontMontserrat;
                color: $middleText;
                font-size: 1.2rem;
                margin-bottom: 1.5rem;
            }

            a {
                background: rgba(0, 0, 0, 0.2);
                @include fontPoppins;
                color: $white;
                padding: 2rem;
                border-radius: 8px;
                display: block;
                width: max-content;
                margin: 4rem auto 2rem;
                transition: background-color .22s, color .22s;
                cursor: pointer;

                &:hover {
                    background: rgba(0, 0, 0, 0.3);
                    color: $main;
                }
            }

            &__offer {
                @include fontMontserrat;
                text-align: center;
                color: $middleText;

            }
        }
    }

</style>

